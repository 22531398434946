$lightblack: #202428;
$darkblack: #16161b;
$lightblue: #08c1b5;
$blue: #194cff;
$fucsia: #eb148a;
$darkblue: #2824e6;

.cardGame {
  background-clip: padding-box;
  border: solid 2px transparent;
  border-radius: 10px;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: -2px;
    border-radius: inherit;
    background: linear-gradient(to left, $blue, $lightblue);
  }
}


.game-nav {
  background-clip: padding-box;
  border: solid 2px transparent;
  border-radius: 0 0 10px 10px;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: -2px;
    border-radius: inherit;
    background: linear-gradient(to left, $blue, $lightblue);
  }
}

.card-title {
  color: white !important;
  :hover {
    color: white !important;
  }
}