.cover {
    width: 90%;
    margin-right: 5%;
    margin-left: 5%;
    margin-top: 5%;
}

.details {
    width: 90%;
    margin-left: 5%;
    margin-top: 5%;
}

.gradient-text-1 {
    background-image: linear-gradient(to left, #194cff, #08c1b5);
    background-size: 100%;
    background-repeat: repeat;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
}
  
.gradient-text-2 {
    background-image: linear-gradient(to left, #194cff, #08c1b5);
    background-size: 100%;
    background-repeat: repeat;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
}

.description {
    font-weight: 400;
}

.bold {
    font-weight: 700;
}