body {
    overflow-x: hidden;
}

$break-small: 500px;
$break-large: 1200px;

.card-columns {
    @media screen and (max-width: $break-small) {
        -webkit-column-count:2;
        -moz-column-count:2;
        column-count:2;
    }
    @media screen and (min-width: $break-large) {
        -webkit-column-count:4;
        -moz-column-count:4;
        column-count:4;
    }
    -webkit-column-count:3;
    -moz-column-count:3;
    column-count:3;
}