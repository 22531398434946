@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap%27');
body{
  font-family: 'Poppins', sans-serif;
}

.jumbotron{
 background: rgb(32,36,40);
 background: linear-gradient(90deg, rgba(32,36,40,1) 0%, rgba(22,22,27,1) 100%);
 margin-top: 0;
 padding-top: 15px;
 padding-bottom: 0;
}
.lessmargin{
 margin-top: -8%;

}
.moreMargin{

  margin-left: 20px;
}
.color{
  color: rgb(190, 190, 190);

}
.letter{
  padding: 2%;
}
.App-logo {
  height: 40vmin;
  margin-left: 4%;
}
.Iconlogos{
  height: 5vmin;
}


.App-header {
  background: rgb(32,36,40);
  background: linear-gradient(90deg, rgba(32,36,40,1) 0%, rgba(22,22,27,1) 100%);
  min-height: 100vh;
  font-size: calc(10px + 2vmin);
}

.badge {
  font-size: 60% !important;
}



