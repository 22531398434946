
.form-control {
  background-color: transparent;
  border-color: #575757;
  &:focus, &:invalid, &:not(:placeholder-shown)  {
    color: white;
    background-color: transparent;
    border-color: white;
  }
}

h1{
  background-image: linear-gradient(to left, #194cff, #08c1b5);
  background-size: 100%;
  background-repeat: repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  font-size: 3vw;
}

.espacioBoton{
   margin-top: 30px;

}
.marginTop{

  margin-top: 50px;
}
.espacio{
  margin-left: 100px;
  margin-right: 100px;

}