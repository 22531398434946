.icon {
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(7deg) brightness(101%) contrast(101%);
    height: 1.5em;
    margin-bottom: 30px;
    margin-right: 10px;
    display: inline-block;
    cursor: pointer;

    :hover {
        filter: invert(72%) sepia(68%) saturate(5146%) hue-rotate(138deg) brightness(100%) contrast(94%);
    }
}

.selected {
    filter: invert(72%) sepia(68%) saturate(5146%) hue-rotate(138deg) brightness(100%) contrast(94%);
}