@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap");

$lightblack: #202428;
$darkblack: #16161b;
$lightblue: #08c1b5;
$blue: #194cff;
$fucsia: #eb148a;
$darkblue: #2824e6;

/*#EB148A, #2824E6;*/
/*#08C1B5, #194CFF*/
/*#D6FF5B, #14FEC6*/

body {
  font-family: "Poppins", sans-serif !important;
  background: rgb(32, 36, 40);
  background: linear-gradient(90deg, $lightblack 0%, $darkblack 100%);
  height: 100%;
}

.log,
.sig {
  height: 100%;
  margin-top: 8vw;
  margin-bottom: 8vw;
  display: -webkit-flex;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.backIcon {
  cursor: pointer;
  font-size: 50px;
  border-radius: 50%;
  overflow: hidden;
  width: 45px;
  height: 45px;
  position: absolute;
  left: 15px;
  top: 15px;
  color: $lightblack;
}

.log .backIcon {
  background: linear-gradient(90deg, $lightblue 0%, $blue 100%);
}

.sig .backIcon {
  background: linear-gradient(90deg, $fucsia 0%, $darkblue 100%);
}

.gameGaTitle {
  width: 50%;
  height: 100%;
  padding-bottom: 30px !important;
}

.gameGaTitle h1 {
  font-size: 13vw;
  line-height: 1.1;
  padding: 0;
  margin: 0;
  text-align: center;
  font-weight: 100;
  user-select: none;
  background-color: white;
  background-size: 100%;
  background-repeat: repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}

.log .gameGaTitle h1 {
  background-image: linear-gradient(75deg, $blue, $lightblue);
}

.sig .gameGaTitle h1 {
  background-image: linear-gradient(75deg, $darkblue, $fucsia);
}

.logForm,
.siForm {
  width: 100%;
  height: 100%;
  max-width: 450px;
  padding: 50px;
  margin-left: 2.5rem;
  margin-right: 2.5rem;
  position: relative;
  box-sizing: border-box;
  background: rgb(32, 36, 40);
  background: linear-gradient(90deg, $lightblack 0%, $darkblack 100%);
  color: #fff;
  background-clip: padding-box;
  border: solid 2px transparent;
  border-radius: 15px;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: -2px;
    border-radius: inherit;
  }
}

.logForm {
  &:before {
    background: linear-gradient(to left, $blue, $lightblue);
  }
}

.siForm {
  &:before {
    background: linear-gradient(to left, $darkblue, $fucsia);
  }
}

.log .logTitle,
.sig .siTitle {
  font-weight: normal;
  line-height: normal;
  text-align: center;
  margin-bottom: 40px;
  user-select: none;
  background-color: black;
  background-size: 100%;
  background-repeat: repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}

.log .logTitle {
  background-image: linear-gradient(to left, $blue, $lightblue);
}

.sig .siTitle {
  background-image: linear-gradient(to left, $darkblue, $fucsia);
}

.log .textS,
.sig .textS {
  margin-top: 1rem;
  font-weight: 300;
}

.log .form-control,
.sig .form-control {
  background-color: transparent;
  border-color: #575757;
  outline: 0 none !important;
  color: white;
  outline-width: 0 !important;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  &:focus,
  &:not(:placeholder-shown) {
    background-color: transparent;
    border-color: white;
  }
}

.sig .form-control {
  &:invalid {
    border-color: red;
  }
}

.log .custom-select,
.sig .custom-select {
  background-color: transparent;
  border-color: #575757;
  outline: 0 none !important;
  outline-width: 0 !important;
  color: white;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  &:focus,
  &:not(:placeholder-shown),
  &:invalid {
    background-color: transparent;
    border-color: white;
  }
}

.sig select * {
  background-color: $lightblack;
}

.sig select option:first-child {
  display: none;
}

.log .form-group,
.sig .form-group {
  margin-bottom: 0;
  margin-top: 1rem;
}

.log .form-control::placeholder,
.sig .form-control::placeholder {
  color: #a9a9a9;
  font-size: 14px;
}

.submitBtn,
.changeBtn {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.submitBtn {
  margin-top: 20px;
  height: 40px;
  margin-bottom: 20px;
}

.changeBtn {
  height: 30px;
}

.submitBtn .center {
  width: 180px;
  height: 40px;
  position: absolute;
}

.changeBtn .center {
  width: 100px;
  height: 30px;
  position: absolute;
}

.btnH {
  cursor: pointer;
  background: transparent;
  outline: none;
  transition: 1s ease-in-out;
  border: 10px solid;
  border-image-slice: 1;
  border-width: 1px;
}

.submitBtn .btnH {
  width: 180px;
  height: 40px;
}

.changeBtn .btnH {
  width: 100px;
  height: 30px;
}

.log .btnH {
  border-image-source: linear-gradient(to left, $blue, $lightblue);
}

.sig .btnH {
  border-image-source: linear-gradient(to left, $darkblue, $fucsia);
}

.submitBtn svg,
.changeBtn svg {
  position: absolute;
  left: 0;
  top: 0;
  fill: none;
  stroke: white;
  stroke-dasharray: 150 480;
  stroke-dashoffset: 150;
  transition: 1s ease-in-out;
}

.btnH:hover {
  transition: 1s ease-in-out;
  background: $darkblack;
}

.btnH:hover svg {
  stroke-dashoffset: -480;
}

.log span,
.sig span {
  color: white;
  font-weight: 100;
}
.submitBtn span {
  font-size: 18px;
}

.changeBtn span {
  font-size: 0.75rem;
}
